import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AdalService } from 'adal-angular4';

/*
 * Guard to prevent navigating to authenticated routes
 * Routes to login route, passes returnUrl of requested route.
 * */

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private adalSvc: AdalService
  ) {
  
  }

  canActivateCore(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    var roles = this.adalSvc.userInfo.profile.roles || [];
    if (this.adalSvc.userInfo.authenticated && roles.indexOf('User')>-1) {      
      return true;
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivateCore(route, state);
  
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivateCore(childRoute, state);
  }
  
}
