import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { GlobalService } from './../../../services/global.service';

@Component({
	selector: 'nav-top',
	styleUrls: ['./nav-top.component.css'],
	templateUrl: './nav-top.component.html'
})
export class TopNavComponent implements OnInit {

  public shrinkNavbar = false;
  bhiDate :any;
  zondaDate:any;
  public Is9Box:boolean;
   constructor(private adalService: AdalService,
               private router:Router,
               private globalService:GlobalService) {
                this.router.events.subscribe(event => {
                  if (event instanceof NavigationEnd) {
                    if (event.url == '/9box')
                      this.Is9Box = true;
                     
                  }
                });
               }

   ngOnInit(): void {
     //this.globalService.Is9Box.subscribe(data => this.Is9Box = data);
     if (this.adalService.userInfo.authenticated) {
       this.globalService.getSources().subscribe(
         data => {
           this.bhiDate = data.filter(a => a.Source == "BHI")[0].Updated;
           this.zondaDate = data.filter(a => a.Source == "Zonda")[0].Updated;

         }
       )
     }
   }

  login(): void {
    var returnUrl = this.router.url == '/logout' ? sessionStorage['app'] == '9box'?'/9box': '': this.router.url; //return to current uri, unless on the logout page
    sessionStorage.removeItem('app');
    this.router.navigate(["login"], { queryParams: { returnUrl: returnUrl } });
   }

  logout(): void {
    if(this.Is9Box)
      sessionStorage['app'] = '9box';
      this.router.navigate(["logout"]);
   }

   get authenticated(): boolean {
	   return this.adalService.userInfo.authenticated;
   }

}
