import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';



@Component({
  selector: 'full-screen-modal',
  styleUrls: ['./full-screen-modal.component.css'],
  templateUrl: './full-screen-modal.component.html'
})
export class FullScreenModalComponent implements OnInit {

  @Input()
  navigateOnClose: boolean = true;
  @Input()
  title: string = '';
  @Input() nochange:boolean


  @Input()
  set close(value: boolean) {
    if (value) {
      this.closeModal();
    }
  }

  @Input()
  container: boolean = false;




  @Output() closed = new EventEmitter<void>();

  constructor() { }


  ngOnInit(): void {
  }



  closeModal(): void {
    if (this.navigateOnClose && !this.nochange)
      window.history.back();
    else 
      this.closed.emit();
  }

  get containerClass(): string {
    return this.container ? "container" : "container-fluid";
  }
}
