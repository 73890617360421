<div class="modal-header {{headerClass}}">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close(dismissAction)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{description}} </p>
</div>
<div class="modal-footer">
  <button *ngFor="let action of actions" type="button" class="btn {{buttonClass}}" (click)="activeModal.close(action.result)">{{action.name}}</button>
</div>
