import { Injectable } from '@angular/core';
import { DialogConstants } from './dialog.constants'
import {DialogComponent} from '../components/dialog/dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DialogService {

    constructor(private modalService: NgbModal) { }

    readonly Result:any = DialogConstants.Result;
    
    error () {
        return this.showDialog(DialogComponent, {
            title: 'Error',
            description: 'Something Went Wrong',
            actions: [ { name: 'Close', result: DialogConstants.Result.DISMISSED } ],
            type: DialogConstants.Type.ERROR
        });
    }

    success(message:string) {
        return this.showDialog(DialogComponent, {
            title: 'Success',
            description: message,
            actions: [ { name: 'Ok', result: DialogConstants.Result.DISMISSED } ],
            type: DialogConstants.Type.SUCCESS
        });
    }

    warning(message:string) {
        return this.showDialog(DialogComponent, {
            title: 'Warning',
            description: message,
            actions: [ { name: 'Close', result: DialogConstants.Result.DISMISSED } ],
            type: DialogConstants.Type.WARNING
        });
    }

    confirm (message:string) {
        return this.showDialog(DialogComponent, {
            title: 'Warning',
            description: message,
            actions: [
                { name: 'Yes', result: DialogConstants.Result.CONFIRMED },
                { name: 'No', result: DialogConstants.Result.DENIED },
            ],
            type: DialogConstants.Type.WARNING
        });
    }

    confirm1 (message:string) {
        return this.showDialog(DialogComponent, {
            title: 'Warning',
            description: message,
            actions: [
                { name: 'Continue', result: DialogConstants.Result.CONFIRMED },
                { name: 'Cancel', result: DialogConstants.Result.DENIED },
            ],
            type: DialogConstants.Type.WARNING
        });
    }

    showDialog (content:any, data:any) {
        const modalRef = this.modalService.open(content);
        modalRef.componentInstance.setupDialog(data);
        return modalRef;        
    }

}



