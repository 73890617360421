import { Component, OnInit } from '@angular/core';
import { AdalService } from 'adal-angular4';

import { environment } from '../../../environments/environment';
import {HTTPStatus} from '../../services/auth.interceptor'
import { Router, NavigationEnd } from '@angular/router';
import { AppInsightsService } from '../../services/app-insights.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  
})

export class AppComponent implements OnInit {
  loading: boolean;


   constructor(private adalService: AdalService,
               private httpStatus: HTTPStatus,
     private router: Router,
               private appInsights:AppInsightsService
               ) {
	   // Init requires object with clientId and tenant properties

     adalService.init(environment.azureAuthProvider);
     this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).dataLayer.push({ 'eventData': event.urlAfterRedirects, 'eventType': 'pageview', 'event': 'gaEvent' });
        this.appInsights.logPageView(event.urlAfterRedirects, event.urlAfterRedirects);
      }
    });
   }
  ngOnInit() {
    this.adalService.handleWindowCallback();
    this.httpStatus.getHttpStatus().subscribe(async (status: boolean) => {
      this.loading = await status;
    });
  }


   
}
