<div class="ampp-modal-background bg-light"></div>
<div class="ampp-modal bg-light pt-2">
  <div class="{{containerClass}}">
    <div class="ampp-modal-header bg-light">
      <div class="h5 ampp-modal-title">
        {{title}}
      </div>


  <div *ngIf ="!nochange">
    <button (click)="closeModal()" class="h4 ampp-modal-close close">x</button>
  </div>

    

    </div>
    <div class="ampp-modal-body">
      <ng-content (onClose)="closeModal()"></ng-content>
    </div>
  </div>
</div>
