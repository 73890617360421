export const environment = {
   apiUrl: 'https://amppapi.qa.pulte.com/odata/',
   azureAuthProvider: {
      'clientId': '3d349787-550f-4c90-9cb2-338836e4b7f3',
     'tenant': 'pulte.onmicrosoft.com',
     redirectUri: 'https://ampp.qa.pulte.com/login',
     postLogoutRedirectUri: 'https://ampp.qa.pulte.com/logout'
   },
   envName: 'qa',
  production: false,
  applicationInsights: {
    instrumentationKey: '6c320bd3-8c9a-4d81-b2cb-1556b9eef240'
  }
};
