import { Component, OnInit,Input } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { AdminService } from 'src/app/ampp-module/services/admin.service';
import { Breadcrumb } from '../../models/breadcrumb.model';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  constructor(private admin: AdminService,
              private adalService: AdalService) { }

  ngOnInit() {
    this.getAdmins()
  }
  @Input()
  breadcrumbs : Breadcrumb;
  isAdmin:boolean;
  getAdmins(){
    this.admin.getAdmins().subscribe(data =>{
      this.isAdmin = data.value.filter(a=> a.LoginName.toLowerCase() == this.adalService.userInfo.profile.upn.toLowerCase()).length > 0
    })
  }
}
