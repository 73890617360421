import { Component, OnInit,Output, EventEmitter} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogConstants } from '../../services/dialog.constants'

@Component({
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  title:string = "Error";
  description:string = "Something Went Wrong";
  actions:any = [ { name: "Ok", result: DialogConstants.Result.DISMISSED } ];
  buttonClass:string = "btn-primary";
  headerClass:string = "";
  dismissAction:any = DialogConstants.Result.DISMISSED;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  setupDialog (data:any) {

    if (data) {

      if (data.title) this.title = data.title;
      if (data.description) this.description = data.description;
      if (data.actions) this.actions = data.actions;

      if (data.type === DialogConstants.Type.ERROR) {
        this.buttonClass = "btn-primary";
        this.headerClass = "text-danger";
      }

      if (data.type === DialogConstants.Type.SUCCESS) {
        this.buttonClass = "btn-primary";
        this.headerClass = "text-success";
      }
    }
  }
}
