export class DialogConstants {
    
    public static Result = {
        CONFIRMED: 1,
        DENIED: 2,
        DISMISSED: 3
    }

    public static Type = {
        ERROR: 1,
        SUCCESS: 2,
        WARNING: 3
    }
}