import { Component, OnInit } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HTTPStatus } from '../../services/auth.interceptor'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public notAuthorized = false;

  constructor(private adalService: AdalService,
    private route: ActivatedRoute,
    private router: Router) {

  }

  ngOnInit() {
    var roles = this.adalService.userInfo.profile.roles || [];
    if (!this.adalService.userInfo.authenticated ) {
      //store redirect url in session
      sessionStorage['returnUrl'] = sessionStorage['app'] != '9box' ? this.route.snapshot.queryParams['returnUrl'] || '/' : '/9box';
       this.adalService.login();
    } else {
      if (roles.indexOf("User")>-1) {
        //navigate to requested page
        var returnUrl = sessionStorage['returnUrl'] || '/';
        sessionStorage.removeItem('returnUrl');
        this.router.navigate([returnUrl]);
      } else {
        this.notAuthorized = true;
      }
    }
  }

}
